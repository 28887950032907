// Load Active Admin's styles into Webpacker,
// see `active_admin.scss` for customization.
import "../stylesheets/active_admin";

import "@activeadmin/activeadmin";
import "activeadmin_addons"
require('activeadmin_blaze_theme');
import "@fortawesome/fontawesome-free/css/all.css"
require('activeadmin_quill_editor')

// https://github.com/select2/select2/issues/5993#issuecomment-1336211725
$(document).on('select2:open', () => {
  (list => list[list.length - 1])(document.querySelectorAll('.select2-container--open .select2-search__field'))?.focus()
});


$(document).on('select2:select select2:clear', evt => {
  let event = new Event('change', { bubbles: true }) // fire a native event
  evt.target.dispatchEvent(event);
})
